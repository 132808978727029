import { useCallback, useState } from 'react';
import {
  Button,
  LegacyStack,
  Text,
  Thumbnail,
  DropZone,
  Spinner,
  Box,
  InlineStack,
} from '@shopify/polaris';
import { DeleteMajor, UploadMajor } from '@shopify/polaris-icons';
import { useField } from 'formik';
import { onFileChange } from '@/util/upload-file';
import { useToaster } from '@/hooks';
import styled from 'styled-components';

const Dropzone = ({ name, onDelete, ...props }) => {
  const [field, , helpers] = useField(name);
  const toast = useToaster();
  const [loading, setLoading] = useState(false);

  const handleDrop = useCallback(
    (_dropFiles, acceptedFiles) => {
      onFileChange(acceptedFiles[0], toast, setLoading, helpers.setValue);
    },
    [helpers.setValue, toast]
  );

  const handleDelete = (event) => {
    event.stopPropagation();
    helpers.setValue(null);
    onDelete?.();
  };

  const renderContent = () => {
    if (loading) {
      return (
        <StyledBox>
          <Spinner size="small" />
        </StyledBox>
      );
    }

    if (field.value) {
      return <File file={field.value} handleRemove={handleDelete} />;
    }

    return (
      <InlineStack align="center">
        <Box width="28px" height="28px" padding="100">
          <UploadMajor />
        </Box>
      </InlineStack>
    );
  };

  return (
    <DropZone onDrop={handleDrop} {...props}>
      {renderContent()}
    </DropZone>
  );
};

const File = ({ file, handleRemove }) => {
  return (
    <div style={{ padding: '4px' }}>
      <LegacyStack vertical>
        <LegacyStack alignment="center">
          <LegacyStack>
            <Thumbnail size="small" alt={file.name} source={file.url} />
            <div>
              {file.name}{' '}
              <Text variant="bodySm" as="p">
                {file.size} bytes
              </Text>
            </div>
          </LegacyStack>
          <Button icon={DeleteMajor} onClick={handleRemove} variant="plain" />
        </LegacyStack>
      </LegacyStack>
    </div>
  );
};

export default Dropzone;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
`;
