import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAppBridge } from '@shopify/app-bridge-react';

const AppBridge = ({ children }) => {
  const shopify = useAppBridge();
  const router = useRouter();

  useEffect(() => {
    // subscribe to appBridge changes - captures appBridge urls and sends them to Next.js router
    const unsubscribe = shopify.protocol.subscribe(
      'Navigation.redirect.app',
      ({ path }) => {
        if (router.asPath !== path) {
          const newPath = path.includes('/api/shopify/auth') ? '/' : path;
          router.push(newPath);
        }
      }
    );

    return unsubscribe;
  }, [router, shopify.protocol]);

  if (!router.isReady) return null;

  return children;
};

export default AppBridge;
