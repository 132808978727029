import axios from 'axios';
import { useAppBridge } from '@shopify/app-bridge-react';

const instance = axios.create();

// Intercept all requests on this Axios instance
instance.interceptors.request.use(async (config) => {
  try {
    if (typeof window !== 'undefined') {
      const shopify = useAppBridge();
      // eslint-disable-next-line no-param-reassign
      config.headers['x-session-token'] = await shopify.idToken();
    }

    if (!config.headers['x-session-token']) {
      console.log(`Can't find session token`); // eslint-disable-line
    }
  } catch (error) {
    console.error('Error setting session token:', error);
  }
  return config;
});

export default instance;
